<template>
	<div id="centreRight2">
		<div class="bg-color-black">
			<div class="d-flex pt-2 pl-2">
				<span style="color:#5cd9e8">
					<icon name="align-left"></icon>
				</span>
				<span class="fs-xl text mx-2">工单统计</span>
			</div>
			<div class="d-flex jc-center body-box" style=" margin-top: 0;">
				<centreRight2Chart1 :config="config" />
			</div>
		</div>
	</div>
</template>

<script>
	import centreRight2Chart1 from "@/components/echart/centerRight/centerRightChart";
	export default {
		data() {
			return {};
		},
		props: {
			config: {
				type: Object,
				default: () => ({})
			},
		},
		components: {
			centreRight2Chart1
		},
		methods: {

		},
		mounted() {
			
		}
	};
</script>

<style lang="scss">
	#centreRight2 {
		padding: 0.2rem;
		height: 5rem;
		min-width: 3.75rem;
		border-radius: 0.0625rem;

		.bg-color-black {
			padding: 0.0625rem;
			height: 4.6rem;
			border-radius: 0.125rem;
		}

		.text {
			color: #c3cbde;
		}

		.body-box {
			border-radius: 0.125rem;
			overflow: hidden;
		}
	}
</style>
