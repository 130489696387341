<template>
	<div><Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" height="4.2rem" width="8.5rem"></Echart></div>
</template>

<script>
import Echart from '@/common/echart';
export default {
	data() {
		return {
			options: {}
		};
	},
	components: {
		Echart
	},
	props: {
		cdata: {
			type: Array,
			default: () => []
		},
		xAxis: {
			type: Array,
			default: () => []
		}
	},
	watch: {
		cdata: {
			handler(newData) {
				this.options = {
					color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} 个'
					},
					toolbox: {
						show: true
					},
					legend: {
						top: 'bottom'
					},
					calculable: true,
					xAxis: {
						type: 'category',
						data: this.xAxis
					},
					yAxis: {
						type: 'value'
					},
					series: newData
				};
			},
			immediate: true,
			deep: true
		}
	}
};
</script>
