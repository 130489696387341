<template>
  <div>
    <Chart :cdata="config" :names="legend" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  components: {
    Chart,
  },
	props: {
		config: {
			type: Array,
			default: () => ([])
		}
	},
	data() {
		return {
			drawTiming: null,
			legend: [],
			cdata: [],
			index: 0
		};
	},
  methods: {
    drawTimingFn () {
      this.setData();
      // this.drawTiming = setInterval(() => {
      //   this.setData();
      // }, 10000);
    },
    setData() {
			// this.legend = [];
			// this.cdata = [];
			// if (Array.isArray(this.config) && this.config.length > 0) {
			// 	let legend = [];
			// 	this.config.forEach(item => {
			// 		legend.push({name: item.name});
			// 	});
			// 	this.legend = legend;
			// 	if (this.index >= this.config.length) {
			// 		this.index = 0;
			// 	}
			// 	let item = this.config[this.index];
			// 	if (Array.isArray(item.values) && item.values.length > 0) {
			// 		item.values.forEach(item => {
			// 			this.cdata.push({index: this.index, count: item.count});
			// 		});
			// 	}
			// 	this.index ++;
			// }
    }
  },
	mounted () {
		this.drawTimingFn();
	},
	beforeDestroy () {
		clearInterval(this.drawTiming);
	}
};
</script>

<style lang="scss" scoped>
</style>