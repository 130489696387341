<template>
	<modal name="login-modal" transition="pop-out" :width="modalWidth" :focus-trap="true" :height="260">
		<div class="box">
			<div class="partition" id="partition-register">
				<div class="partition-title">登 录</div>
				<div class="partition-form">
					<form autocomplete="false">
						<!-- <div class="autocomplete-fix"><input disabled type="password" /></div> -->
						<input id="n-username" type="text" placeholder="输入账号" v-model="username" />
						<input id="n-password2" type="password" placeholder="输入密码" class="password" v-model="password" />
					</form>
					<div style="margin-top: 30px"></div>
					<div class="button-set">
						<button id="goto-signin-btn" @click="cancel">取消登录</button>
						<button id="cancel-signin-btn" @click="signIn">立即登录</button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>
<script>
const MODAL_WIDTH = 356;

export default {
	name: 'LoginModal',
	data() {
		return {
			modalWidth: MODAL_WIDTH,
			username: '',
			password: ''
		};
	},
	created() {
		this.username = '';
		this.password = '';
		this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
	},
	methods: {
		signIn() {
			if (this.username.length <= 0 || this.password.length <= 0) {
				return;
			}
			this.$emit('signIn', this.username, this.password);
		},
		cancel() {
			this.$emit('close');
		}
	}
};
</script>
<style lang="scss">
$background_color: #404142;
$github_color: #dba226;
$facebook_color: #3880ff;

.box {
	background: white;
	overflow: hidden;
	width: 356px;
	height: 260px;
	border-radius: 2px;
	box-sizing: border-box;
	box-shadow: 0 0 40px black;
	color: #8b8c8d;
	font-size: 0;

	.partition {
		width: 100%;
		height: 100%;

		.partition-title {
			box-sizing: border-box;
			padding: 30px;
			width: 100%;
			text-align: center;
			letter-spacing: 1px;
			font-size: 20px;
			font-weight: 300;
		}

		.partition-form {
			padding: 0 20px;
			box-sizing: border-box;
			
			.password {
				margin-top: 16px;
			}
		}
	}

	input[type='password'],
	input[type='text'] {
		display: block;
		box-sizing: border-box;
		margin-bottom: 4px;
		width: 100%;
		font-size: 12px;
		line-height: 2;
		border: 0;
		border-bottom: 1px solid #dddedf;
		padding: 4px 8px;
		font-family: inherit;
		transition: 0.5s all;
	}

	button {
		background: white;
		border-radius: 4px;
		box-sizing: border-box;
		padding: 10px;
		letter-spacing: 1px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
		min-width: 140px;
		margin-top: 8px;
		color: #8b8c8d;
		cursor: pointer;
		border: 1px solid #dddedf;
		text-transform: uppercase;
		transition: 0.1s all;
		font-size: 10px;
		&:hover {
			border-color: mix(#dddedf, black, 90%);
			color: mix(#8b8c8d, black, 80%);
		}
	}

	.large-btn {
		width: 100%;
		background: white;

		span {
			font-weight: 600;
		}
		&:hover {
			color: white !important;
		}
	}

	.button-set {
		margin-bottom: 8px;
	}

	#cancel-signin-btn,
	#signin-btn {
		margin-left: 34px;
		background: #3880FF;
		color: #FFFFFF;
	}

	.autocomplete-fix {
		position: absolute;
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
		width: 0;
		height: 0;
		left: 0;
		top: 0;
	}
}

.pop-out-enter-active,
.pop-out-leave-active {
	transition: all 0.5s;
}

.pop-out-enter,
.pop-out-leave-active {
	opacity: 0;
	transform: translateY(24px);
}
</style>
