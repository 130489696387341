<template>
	<div>
		<div style="height: 0.5rem;"></div>
		<Echart :options="options" id="centreLeft1Chart" height="5.6rem" width="100%"></Echart>
	</div>
</template>

<script>
import Echart from '@/common/echart';
export default {
	data() {
		return {
			options: {}
		};
	},
	components: {
		Echart
	},
	props: {
		cdata: {
			type: Array,
			default: () => []
		},
		labelOption: {
			normal: {
				rotate: 90,
				align: 'left',
				verticalAlign: 'middle',
				position: 'insideBottom',
				distance: 15
			}
		}
	},
	watch: {
		cdata: {
			handler(newData) {
				if (!Array.isArray(newData) || newData.length <= 0) {
					return;
				}

				let names = [];
				let series = [];
				newData.forEach(item => {
					let data = [];
					item.values.forEach(value => {
						data.push(value.count);
					});
					series.push({name:item.name, type:'bar', barGap:0, barCategoryGap: '15%', label: this.labelOption, emphasis: {focus: 'series'}, data: data});
					names.push(item.name);
				});

				this.options = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						data: names
					},
					toolbox: {
						show: false,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: { show: true },
							dataView: { show: true, readOnly: false },
							magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
							restore: { show: true },
							saveAsImage: { show: true }
						}
					},
					xAxis: {
						type: 'category',
						axisTick: {show: true},
						data: ['10', '20', '39', '40', '50', '60', '70', '80', '90', '100']
					},
					yAxis: {
						type: 'value'
					},
					series: series
				};
			},
			immediate: true,
			deep: true
		}
	}
};
</script>
