<template>
	<div>
		<Echart :options="options" id="centreLeft1Chart" height="3.6rem" width="3.6rem"></Echart>
	</div>
</template>

<script>
import Echart from '@/common/echart';
export default {
	data() {
		return {
			options: {}
		};
	},
	components: {
		Echart
	},
	props: {
		cdata: {
			type: Object,
			default: () => ({})
		}
	},
	watch: {
		cdata: {
			handler(newData) {
				this.options = {
					color: [
						'#37a2da',
						'#fb7293',
						'#ff9f7f',
						'#ffdb5c',
						'#32c5e9',
						'#9fe6b8',
						'#e7bcf3',
						'#8378ea'
					],
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} ({d}%)'
					},
					toolbox: {
						show: true
					},
					calculable: true,
					series: [
						{
							name: '盒子状态',
							type: 'pie',
							radius: [10, 60],
							roseType: false,
							center: ['50%', '50%'],
							data: newData.seriesData
						}
					]
				};
			},
			immediate: true,
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
