<template>
	<div id="centreLeft2">
		<div class="bg-color-black">
			<div class="d-flex pt-2 pl-2">
				<span style="color:#5cd9e8">
					<icon name="chart-pie"></icon>
				</span>
				<div class="d-flex">
					<span class="fs-xl text mx-2">实时数据</span>
					<span class="fs-xl subtext mx-2">{{ current }}</span>
					<dv-decoration-1 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
				</div>
			</div>
			<div class="d-flex jc-center">
				<CentreLeft2Chart :config="config"/>
			</div>
		</div>
	</div>
</template>

<script>
	import CentreLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
	export default {
		data() {
			return {
				current: ''
			};
		},
		props: {
			config: {
				type: Object,
				default: () => ({})
			}
		},
		watch: {
			config: {
				handler(newData) {
					this.setData(newData);
				},
				immediate: true,
				deep: true
			}
		},
		components: {
			CentreLeft2Chart
		},
		methods: {
			setData(newData) {
				let index = newData.index;
				let weather = newData.weather;
				if (index == 0) {
					this.current = '当前温度: ' + weather.temp + '℃';
				} else if (index == 1) {
					this.current = '当前湿度: ' + weather.humidity + '%';
				} else if (index == 2) {
					this.current = '当前CO2浓度: ';
				} else if (index == 3) {
					this.current = '当前TVOC等级: ';
				} else if (index == 4) {
					this.current = '当前PM2.5浓度: ' + weather.pm25 + '%';
				}
			}
		}
	};
</script>

<style lang="scss">
	#centreLeft2 {
		padding: 0.2rem;
		height: 5rem;
		min-width: 3.75rem;
		border-radius: 0.0625rem;

		.bg-color-black {
			height: 4.6rem;
			border-radius: 0.125rem;
		}

		.text {
			color: #c3cbde;
		}

		.subtext {
			color: #FFFFFF;
		}

		.chart-box {
			margin-top: 0.2rem;
			width: 5.0rem;
			height: 6.5rem;

			.active-ring-name {
				padding-top: 0.125rem;
			}
		}
	}
</style>
