<template>
	<div><Chart :cdata="series" :xAxis="xAxis" /></div>
</template>

<script>
import Chart from './chart.vue';
export default {
	props: {
		config: {
			type: Object,
			default: () => ({})
		}
	},
	components: {
		Chart
	},
	data() {
		return {
			airProductTypes: [
				{ name: '温度', type: 'msLocalTemp' },
				{ name: '湿度', type: 'msLocalHumi' },
				{ name: 'CO2浓度', type: 'msLocalCo2' },
				{ name: 'TVOC等级', type: 'msLocalVoc' },
				{ name: 'PM2.5浓度', type: 'msLocalPm25' }
			],
			xAxis: [],
			series: []
		};
	},
	watch: {
		config: {
			handler(newData) {
				this.setData(newData);
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		setData(newData) {
			if (!Array.isArray(newData.data) || newData.data.length <= 0) {
				return;
			}
			let index = newData.index;
			this.xAxis = [];
			var numList = [];
			newData.data.forEach(item => {
				this.xAxis.push(item.value);
				numList.push(item.count);
			});
			// 前
			this.series = [];
			for (var i = 0; i < index; i++) {
				var item = {
					name: this.airProductTypes[i].name,
					type: 'line',
					radius: [10, 60],
					roseType: false,
					center: ['50%', '40%'],
					data: [null, null, null, null, null]
				};
				this.series.push(item);
			}
			// 中
			var currentItem = {
				name: this.airProductTypes[index].name,
				type: 'line',
				radius: [10, 60],
				roseType: false,
				center: ['50%', '40%'],
				data: numList
			};
			this.series.push(currentItem);
			// 后
			for (var j = index + 1; j < this.airProductTypes.length; j++) {
				var jtem = {
					name: this.airProductTypes[j].name,
					type: 'line',
					radius: [10, 60],
					roseType: false,
					center: ['50%', '40%'],
					data: [null, null, null, null, null]
				};
				this.series.push(jtem);
			}
		}
	}
};
</script>

<style lang="scss" scoped></style>
