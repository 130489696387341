<template>
  <div>
    <Echart
      :options="options"
      id="centreRight2Chart1"
      height="4.0rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          color: [
						"#de6e6a",
            "#5a6fc0",
            "#9eca7f",
						"#f2ca6b"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
            top: 'bottom'
          },
          series: [
            {
              name: "工单统计",
              type: "pie",
              radius: [10, 60],
              roseType: 'area',
              center: ["50%", "40%"],
              data: newData.seriesData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
