<template>
	<div id="centreRight1">
		<div class="bg-color-black">
			<div class="d-flex pt-2 pl-2">
				<span style="color:#5cd9e8">
					<icon name="chart-line"></icon>
				</span>
				<div class="d-flex">
					<span class="fs-xl text mx-2">工单事件</span>
				</div>
			</div>
			<div class="d-flex jc-center body-box">
				<dv-scroll-board ref="scrollBoard" :config="config" style="width:100%;height:4.0rem" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			config: {
				type: Object,
				default: () => ({})
			},
		}
	};
</script>

<style lang="scss">
	#centreRight1 {
		padding: 0.2rem;
		height: 5rem;
		min-width: 3.75rem;
		border-radius: 0.0625rem;

		.bg-color-black {
			height: 4.6rem;
			border-radius: 0.125rem;
		}

		.text {
			color: #c3cbde;
		}

		.body-box {
			border-radius: 0.125rem;
			overflow: hidden;
		}
	}
</style>
