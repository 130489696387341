<template>
  <div>
    <Chart :cdata="config" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  props: {
		config: {
			type: Object,
			default: () => ({})
		}
	},
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
