<template>
  <div>
    <Chart :cdata="config" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
	props: {
		config: {
			type: Array,
			default: () => ([])
		}
	},
  components: {
    Chart,
  }
};
</script>

<style lang="scss" scoped>
</style>