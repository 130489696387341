<template>
	<div id="index">
		<!-- 登录弹窗 -->
		<login-modal style="z-index: 9999;" @signIn="login" @close="$modal.hide('login-modal')" />
		<!-- 主视图 -->
		<dv-full-screen-container class="bg">
			<dv-loading v-if="loading">Loading...</dv-loading>
			<div v-else class="host-body">
				<div class="d-flex jc-center">
					<dv-decoration-10 style="width:33.3%;height:.0625rem;" />
					<div class="d-flex jc-center">
						<dv-decoration-8 :color="['#568aea', '#000000']" style="width:2.5rem;height:.625rem;" />
						<div class="title">
							<span class="title-text">{{ app.title }}</span>
							<dv-decoration-6 class="title-bototm" :reverse="true" :color="['#50e3c2', '#67a1e5']" style="width:3.125rem;height:.1rem;" />
						</div>
						<dv-decoration-8 :reverse="true" :color="['#568aea', '#000000']" style="width:2.5rem;height:.625rem;" />
					</div>
					<dv-decoration-10 style="width:33.3%;height:.0625rem; transform: rotateY(180deg);" />
				</div>

				<!-- 第二行 -->
				<div class="d-flex jc-between px-2">
					<div class="d-flex" style="width: 40%">
						<div class="react-right ml-4" style="width: 6.25rem; text-align: left;background-color: #0f1325;">
							<span class="react-before"></span>
							<span class="text">当前天气：{{ weather }}</span>
						</div>
						<div class="react-right ml-3" style="background-color: #0f1325;">
							<span class="text">{{ username }}</span>
							<!-- <span class="text" @click="$modal.show('login-modal')">{{ username }}</span> -->
							<!-- <span class="text" style="margin-right: 0.3rem;float: right;" @click="logOut">退出登录</span> -->
						</div>
					</div>
					<div style="width: 40%" class="d-flex">
						<div class="react-left bg-color-blue mr-3">
							<span class="text fw-b">{{ app.name }}</span>
						</div>
						<div class="react-left mr-4" style="width: 6.25rem; background-color: #0f1325; text-align: right;">
							<span class="react-after"></span>
							<span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
							<span class="text" style="margin-left: 0.6rem;">
								<img :src="s_fullscreen" style="width: 0.375rem;height: 0.375rem;vertical-align: middle;" @click="toggleFullscreen" />
							</span>
						</div>
					</div>
				</div>

				<div class="body-box">
					<!-- 第三行数据 -->
					<div class="content-box">
						<div>
							<!-- <dv-border-box-12 style="width: 3.6rem;height: 5.0rem;"> -->
							<dv-border-box-12><centerLeft1 :cdata="boxData" /></dv-border-box-12>
						</div>
						<div>
							<!-- <dv-border-box-12 style="width: 8.5rem;height: 5.0rem;"> -->
							<dv-border-box-12><centerLeft2 :config="airProductData" /></dv-border-box-12>
						</div>
						<!-- 中间 -->
						<div>
							<!-- <dv-border-box-12 style="width: 7.0rem;height: 5.0rem;"> -->
							<dv-border-box-12><centerRight2 :config="workOrderData" /></dv-border-box-12>
						</div>
						<div>
							<!-- <dv-border-box-13 style="width: 4.9rem;height: 5.0rem;"> -->
							<dv-border-box-13><centerRight1 :config="workOrderEvent" /></dv-border-box-13>
						</div>
					</div>

					<!-- 第四行数据 -->
					<div class="bototm-box">
						<dv-border-box-13><bottomLeft :config="mapData" /></dv-border-box-13>
						<dv-border-box-12><bottomRight :config="consumableData" /></dv-border-box-12>
					</div>
				</div>
			</div>
		</dv-full-screen-container>
	</div>
</template>

<script>
import { tokenStorageKey } from '@/common/user-defaults.js';
import { formatTime } from '../utils/index.js';
import centerLeft1 from './centerLeft1';
import centerLeft2 from './centerLeft2';
import centerRight1 from './centerRight1';
import centerRight2 from './centerRight2';
import bottomLeft from './bottomLeft';
import bottomRight from './bottomRight';
import loginModal from './loginModal';
export default {
	components: {
		centerLeft1,
		centerLeft2,
		centerRight1,
		centerRight2,
		bottomLeft,
		bottomRight,
		loginModal
	},
	data() {
		return {
			s_fullscreen: require('@/assets/fullScreen.png'),
			weatherTiming: null,
			airProductTiming: null,
			loading: true,
			dateDay: null,
			dateYear: null,
			dateWeek: null,
			weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
			app: {
				title: '',
				name: ''
			},
			airProductTypes: [
				{ name: '温度', type: 'msLocalTemp' },
				{ name: '湿度', type: 'msLocalHumi' },
				{ name: 'CO2浓度', type: 'msLocalCo2' },
				{ name: 'TVOC等级', type: 'msLocalVoc' },
				{ name: 'PM2.5浓度', type: 'msLocalPm25' }
			],
			username: '点击登录',
			weatherList: [],
			boxData: {},
			airProductData: {},
			workOrderEvent: {},
			workOrderData: {},
			mapData: [],
			consumableData: [],
			weather: '', // 当前天气
			typeIndex: 0,
			eventPage: 1
		};
	},
	mounted() {
		this.timeFn();
		this.cancelLoading();
		this.getSystem();
		this.login('东旭', 'Meiyoumima@1234');
	},
	methods: {
		timeFn() {
			setInterval(() => {
				this.dateDay = formatTime(new Date(), 'HH: mm: ss');
				this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
				this.dateWeek = this.weekday[new Date().getDay()];
			}, 1000);
		},
		cancelLoading() {
			setTimeout(() => {
				this.loading = false;
			}, 500);
		},
		getSystem() {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/getSystem')
				.then(res => {
					console.log('/api/v1/dashboard/getSystem = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						that.app.title = res.data.data.appTitle;
						that.app.name = res.data.data.appName;
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		login(username, password) {
			var that = this;
			this.$http
				.post('/api/v1/dashboard/login', {
					username: username,
					password: password
				})
				.then(res => {
					console.log('/api/v1/dashboard/login = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						localStorage.setItem(tokenStorageKey, res.data.token);
						// 获取当前用户
						that.getUser();
						// 获取天气
						that.getWeather();
						// 获取盒子数据
						that.getBoxData();
						setTimeout(() => {
							// 获取工单统计
							that.getWorkOrderData();
							// 获取工单事件
							that.getWorkOrderEvent(that.eventPage);
						}, 500);
						setTimeout(() => {
							// 获取地图数据
							that.getMapData();
							// 获取耗材数据
							that.getConsumableData();
						}, 1000);
					}
					that.$modal.hide('login-modal');
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		logOut() {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/logout')
				.then(res => {
					console.log('/api/v1/dashboard/logout = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						that.username = "点击登录";
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getUser() {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/user')
				.then(res => {
					console.log('/api/v1/dashboard/user = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						that.username = res.data.data.userName;
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getWeather() {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/weather')
				.then(res => {
					console.log('/api/v1/dashboard/weather = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						if (Array.isArray(res.data.data) && res.data.data.length > 0) {
							that.weatherList = res.data.data;

							// 定时更新天气数据
							let index = 0;
							that.weather = that.getWeatherDescription(that.weatherList[index]);
							that.weatherTiming = setInterval(() => {
								index++;
								if (index >= that.weatherList.length) {
									index = 0;
								}
								that.weather = that.getWeatherDescription(that.weatherList[index]);
							}, 10000);

							// 定时获取空品数据
							that.getAirProductData(that.airProductTypes[that.typeIndex]);
							that.airProductTiming = setInterval(() => {
								that.typeIndex++;
								if (that.typeIndex >= that.airProductTypes.length) {
									that.typeIndex = 0;
								}
								that.getAirProductData(that.airProductTypes[that.typeIndex].type);
							}, 10000);
						}
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getBoxData() {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/boxData')
				.then(res => {
					console.log('/api/v1/dashboard/boxData = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						var mode = [];
						if (Array.isArray(res.data.data.mode) && res.data.data.mode.length > 0) {
							res.data.data.mode.forEach(item => {
								mode.push(item.name);
							});
						}
						that.boxData = {
							xData: mode,
							seriesData: res.data.data.mode,
							state: res.data.data.state,
							scene: res.data.data.scene
						};
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getAirProductData(type) {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/airProductData', {
					params: {
						type: type
					}
				})
				.then(res => {
					console.log('/api/v1/dashboard/airProductData = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						if (Array.isArray(res.data.data) && res.data.data.length > 0) {
							let weather = that.weatherList[that.typeIndex];
							if (weather) {
								that.airProductData = { index: that.typeIndex, weather: weather, data: res.data.data };
							} else {
								that.airProductData = { index: that.typeIndex, data: res.data.data };
							}
						}
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getMapData() {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/mapData')
				.then(res => {
					console.log('/api/v1/dashboard/mapData = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						if (Array.isArray(res.data.data) && res.data.data.length > 0) {
							that.mapData = res.data.data;
						}
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getConsumableData() {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/consumableData')
				.then(res => {
					console.log('/api/v1/dashboard/consumableData = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						that.consumableData = res.data.data;
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getWorkOrderEvent(page) {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/workOrderEvent', {
					params: {
						current: page,
						pageSize: 99
					}
				})
				.then(res => {
					console.log('/api/v1/dashboard/workOrderEvent = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						let rowDatas = [];
						if (Array.isArray(res.data.data.list) && res.data.data.list.length > 0) {
							res.data.data.list.forEach(item => {
								let row = [];
								row.push(item.createdAt.split(' ')[0], item.project, item.content);
								rowDatas.push(row);
							});
						}
						that.workOrderEvent = {
							// header: ['日期', '操作人', '事件'],
							data: rowDatas,
							rowNum: 7, //表格行数
							// headerHeight: 35,
							// headerBGC: '#0f1325', //表头
							oddRowBGC: '#0f1325', //奇数行
							evenRowBGC: '#171c33', //偶数行
							index: false,
							waitTime: 3000,
							columnWidth: [100],
							align: ['left']
						};
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getWorkOrderData() {
			var that = this;
			this.$http
				.get('/api/v1/dashboard/workOrderData')
				.then(res => {
					console.log('/api/v1/dashboard/workOrderData = ' + JSON.stringify(res.data));
					if (res.data.code == 200) {
						that.workOrderData = {
							xData: ['故障上报', '线下保修', '账号修改', '耗材更换'],
							seriesData: res.data.data
						};
					}
				})
				.catch(err => {
					console.log('****err = ' + err);
				});
		},
		getWeatherDescription(weather) {
			return weather.temp + '℃' + '    湿度:' + weather.humidity + '%' + '    PM25:' + weather.pm25 + '    空气质量:' + weather.aqiLevel;
		},
		getKeyDown(e) {
			console.log("e = " + JSON.stringify(e));
			let _this = this;
			if (e.keyCode === 122) {
				e.preventDefault() || (e.returnValue = false);
				_this.toggleFullscreen(); // 触发全屏的按钮
			} else if (e.keyCode === 27) {
				if (document.fullscreenElement !== null) {
					_this.quitFullScreen();
				}
			}
		},
		toggleFullscreen() {
			let _this = this;
			let el = document.documentElement;
			if (document.fullscreenElement === null) {
				_this.openFullscreen(el);
			} else {
				_this.quitFullscreen();
			}
		},
		openFullscreen(element) {
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.webkitRequestFullScreen) {
				element.webkitRequestFullScreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.msRequestFullscreen) {
				// IE11
				element.msRequestFullscreen();
			}
		},
		quitFullscreen() {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	},
	beforeDestroy() {
		clearInterval(this.weatherTiming);
		clearInterval(this.airProductTiming);
	}
};
</script>

<style lang="scss">
@import '../assets/scss/index.scss';
</style>
