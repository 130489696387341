<template>
	<div><Echart :options="options" id="bottomLeftChart" height="5.6rem" width="100%"></Echart></div>
</template>

<script>
import Echart from '@/common/echart';
import 'echarts/map/js/china.js';
export default {
	components: {
		Echart
	},
	props: {
		cdata: {
			type: Array,
			default: () => ([])
		}
	},
	data() {
		return {
			//echart 配制option
			options: {
				tooltip: {
					triggerOn: 'click', //mousemove、click
					padding: 8,
					borderWidth: 1,
					borderColor: '#409eff',
					backgroundColor: 'rgba(255,255,255,0.7)',
					textStyle: {
						color: '#000000',
						fontSize: 13
					}
				},
				visualMap: {
					show: true,
					left: 26,
					bottom: 40,
					showLabel: true,
					textStyle: {
						color: "#fff"
					},
					pieces: [
						{
							gte: 50,
							label: '>= 50',
							color: '#1f4268'
						},
						{
							gte: 10,
							lt: 50,
							label: '50个以下',
							color: '#6f83db'
						},
						{
							gt: 1,
							lt: 10,
							label: '10个及以下',
							color: '#6195c9'
						},
						{
							lte: 1,
							label: '少于2个',
							color: '#b5cce2'
						}
					]
				},
				geo: {
					map: 'china',
					roam: false, //关闭拖拽
					scaleLimit: {
						min: 1,
						max: 2
					},
					zoom: 1.2,
					label: {
						normal: {
							show: false,
							fontSize: '11',
							color: 'rgba(0,0,0,0.7)'
						},
						emphasis: {
							show: false,
						}
					},
					itemStyle: {
						normal: {
							shadowBlur: 50,
							shadowColor: 'rgba(0, 0, 0, 0.2)',
							borderColor: 'rgba(0, 0, 0, 0.2)'
						},
						emphasis: {
							areaColor: '#f2d5ad',
							shadowOffsetX: 0,
							shadowOffsetY: 0,
							borderWidth: 0
						}
					}
				},
				series: [
					{
						name: '盒子数量',
						type: 'map',
						geoIndex: 0,
						data: []
					}
				]
			},
			//echart data
			dataList: [
				{
					name: '南海诸岛',
					value: 0
				},
				{
					name: '北京',
					value: 0
				},
				{
					name: '天津',
					value: 0
				},
				{
					name: '上海',
					value: 0
				},
				{
					name: '重庆',
					value: 0
				},
				{
					name: '河北',
					value: 0
				},
				{
					name: '河南',
					value: 0
				},
				{
					name: '云南',
					value: 0
				},
				{
					name: '辽宁',
					value: 0
				},
				{
					name: '黑龙江',
					value: 0
				},
				{
					name: '湖南',
					value: 0
				},
				{
					name: '安徽',
					value: 0
				},
				{
					name: '山东',
					value: 0
				},
				{
					name: '新疆',
					value: 0
				},
				{
					name: '江苏',
					value: 0
				},
				{
					name: '浙江',
					value: 0
				},
				{
					name: '江西',
					value: 0
				},
				{
					name: '湖北',
					value: 0
				},
				{
					name: '广西',
					value: 0
				},
				{
					name: '甘肃',
					value: 0
				},
				{
					name: '山西',
					value: 0
				},
				{
					name: '内蒙古',
					value: 0
				},
				{
					name: '陕西',
					value: 0
				},
				{
					name: '吉林',
					value: 0
				},
				{
					name: '福建',
					value: 0
				},
				{
					name: '贵州',
					value: 0
				},
				{
					name: '广东',
					value: 0
				},
				{
					name: '青海',
					value: 0
				},
				{
					name: '西藏',
					value: 0
				},
				{
					name: '四川',
					value: 0
				},
				{
					name: '宁夏',
					value: 0
				},
				{
					name: '海南',
					value: 0
				},
				{
					name: '台湾',
					value: 0
				},
				{
					name: '香港',
					value: 0
				},
				{
					name: '澳门',
					value: 0
				}
			]
		};
	},
	watch: {
		cdata: {
			handler(newData) {
				if (Array.isArray(newData) && newData.length > 0) {
					this.dataList.forEach(item => {
						let index = newData.findIndex(data => { return data.name.indexOf(item.name) != -1 });
						if (index >= 0) {
							item.value = newData[index].value;
						}
					})
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		//初始化中国地图
		initEchartMap() {
			let mapDiv = document.getElementById('bottomLeftChart');
			let myChart = this.$echarts.init(mapDiv);
			myChart.setOption(this.options);
		},
		//修改echart配制
		setEchartOption() {
			this.options.series[0]['data'] = this.dataList;
		}
	},
	created() {
		this.setEchartOption();
	},
	mounted() {
		this.$nextTick(() => {
			this.initEchartMap();
		});
	}
};
</script>
