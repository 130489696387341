<template>
	<div id="centreLeft1">
		<div class="bg-color-black">
			<div class="d-flex pt-2 pl-2">
				<span style="color:#5cd9e8">
					<icon name="chart-bar"></icon>
				</span>
				<div class="d-flex">
					<span class="fs-xl text mx-2">网关状态</span>
					<dv-decoration-3 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
				</div>
			</div>
			<div class="d-flex jc-center">
				<CentreLeft1Chart :cdata="cdata" />
			</div>
			<div class="d-flex jc-center">
				<span style="height:0.4rem;line-height:0.4rem;margin-right:0.1rem;">在线</span>
				<span style="width:1.8rem;">
					<span :style="onlineStyle"></span>
					<span :style="offlineStyle"></span>
				</span>
				<span style="height:0.4rem;line-height:0.4rem;margin-left:0.1rem;">离线</span>
			</div>
			<!-- <div class="d-flex jc-center" style="margin-top: 0.2rem;">
				<span style="width:92%;">
					<span :style="{'display':'inline-block', 'background-color':'#51b282', 'height':'0.4rem', 'width':getSceneWidth_1}"></span>
					<span :style="{'display':'inline-block', 'background-color':'#a17c58', 'height':'0.4rem', 'width':getSceneWidth_2}"></span>
					<span :style="{'display':'inline-block', 'background-color':'#3f8ebc', 'height':'0.4rem', 'width':getSceneWidth_3}"></span>
				</span>
			</div>
			<div class="d-flex jc-center">
				<span style="width:92%;">
					<span :style="{'display':'inline-block', 'text-align':'center', 'margin-top':'0.06rem', 'width':getSceneWidth_1}">{{ getSceneName_1 }}</span>
					<span :style="{'display':'inline-block', 'text-align':'center', 'margin-top':'0.06rem', 'width':getSceneWidth_2}">{{ getSceneName_2 }}</span>
					<span :style="{'display':'inline-block', 'text-align':'center', 'margin-top':'0.06rem', 'width':getSceneWidth_3}">{{ getSceneName_3 }}</span>
				</span>
			</div> -->
		</div>
	</div>
</template>

<script>
	import CentreLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
	export default {
		data() {
			return {
				config: {
					lineWidth: 30,
					activeRadius: "80%",
					radius: "75%",
					activeTimeGap: 2000,
				},
				stateWidth: 1.8,
			};
		},
		props: {
			cdata: {
				type: Object,
				default: () => ({})
			},
		},
		components: {
			CentreLeft1Chart
		},
		computed: {
			onlineStyle() {
				let config = this.cdata;
				if (Array.isArray(config.state) && config.state.length > 1) {
					let total = config.state[0].value + config.state[1].value;
					let width = this.stateWidth * (config.state[0].value / total);
					return "display:inline-block;background-color:#3f8c30; height:0.4rem; width:" + width + "rem;";
				} else {
					return "display:inline-block;background-color:#3f8c30; height:0.4rem; width:0.5rem;";
				}
			},
			offlineStyle() {
				let config = this.cdata;
				if (Array.isArray(config.state) && config.state.length > 1) {
					let total = config.state[0].value + config.state[1].value;
					let width = this.stateWidth * (config.state[1].value / total);
					return "display:inline-block;background-color:#2f3864; height:0.4rem; width:" + width + "rem;";
				} else {
					return "display:inline-block;background-color:#3f8c30; height:0.4rem; width:0.5rem;";
				}
			},
			getSceneWidth_1() {
				let scene = this.cdata.scene;
				if (Array.isArray(scene) && scene.length >= 3) {
					let total = scene[0].value + scene[1].value + scene[2].value;
					if (total > 0) {
						return (94 * scene[0].value / total).toString() + '%';
					}
				}
				return 0;
			},
			getSceneWidth_2() {
				let scene = this.cdata.scene;
				if (Array.isArray(scene) && scene.length >= 3) {
					let total = scene[0].value + scene[1].value + scene[2].value;
					if (total > 0) {
						return (94 * scene[1].value / total).toString() + '%';
					}
				}
				return 0;
			},
			getSceneWidth_3() {
				let scene = this.cdata.scene;
				if (Array.isArray(scene) && scene.length >= 3) {
					let total = scene[0].value + scene[1].value + scene[2].value;
					if (total > 0) {
						return (94 * scene[2].value / total).toString() + '%';
					}
				}
				return 0;
			},
			getSceneName_1() {
				let scene = this.cdata.scene;
				if (Array.isArray(scene) && scene.length >= 3) {
					return scene[0].value > 0 ? scene[0].name : "";
				}
				return "";
			},
			getSceneName_2() {
				let scene = this.cdata.scene;
				if (Array.isArray(scene) && scene.length >= 3) {
					return scene[1].value > 0 ? scene[1].name : "";
				}
				return "";
			},
			getSceneName_3() {
				let scene = this.cdata.scene;
				if (Array.isArray(scene) && scene.length >= 3) {
					return scene[2].value > 0 ? scene[2].name : "";
				}
				return "";
			}
		},
		methods: {
			changeNumber() {
				if (Array.isArray(this.numberData) && this.numberData.length > 0) {
					this.numberData.forEach((item, index) => {
						item.number.number[0] += ++index;
						item.number = {
							...item.number
						};
					});
				}
			}
		}
	};
</script>

<style lang="scss">
	#centreLeft1 {
		padding: 0.2rem;
		height: 5rem;
		min-width: 3.6rem;
		border-radius: 0.0625rem;

		.bg-color-black {
			height: 4.6rem;
			border-radius: 0.125rem;
			
			.jc-state {
				margin-left: 0.2rem;
				width: 2.8rem;
			}
		}

		.text {
			color: #c3cbde;
		}

		.chart-box {
			margin-top: 0.2rem;
			width: 3.6rem;
			height: 2.125rem;

			.active-ring-name {
				padding-top: 0.125rem;
			}
		}
	}
</style>
